@import url('https://fonts.googleapis.com/css?family=Proza+Libre');

$primary: #0e76bc; /* MAIN COLOR */
$secondary: #25aae1; /* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Proza Libre', sans-serif;
  color: $gray;
}
.navbar {
  margin-bottom: 0;
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  height: 100px;
  position: relative;

  .navbar-nav>li>a {
    line-height:68px;
    background: $wht;
    @media (max-width:767px){
      line-height: 20px;
    }
  }
  @media (max-width: 767px){
    height: 50px;
    .logo {
      max-height: 50px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.hero-info{
  padding: 50px 0px;
  @media(max-width: 767px){
    img {max-width: 250px}
  }
  h2{
    font-size: 1.5em;
  }
  h1 {
    text-align: center;
  }
  p {
    font-size: 1em;
    line-height: 1.7em;
    padding: 0 20px;
  }
}
.hero-quote {
    padding: 60px 0;
    background: $gray;

    @media(max-width: 767px){
      padding:40px 0;
    }
  h3 {
    font-size: 1.7em;
    line-height:1.6em;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.2em;
    }
  }
  h1 {
    font-size: 2.5em;
    text-align: center;
    line-height:1.6em;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.9em;
    }
  }
}
.hero-process {
  padding: 60px 0;
  background-color: #e2e2e2;
  p {
    font-size: 1.3em;
    line-height: 1.7em;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }
  }
  @media(max-width: 767px){
    img {display: none;}
  }

  @media(max-width: 767px){
    padding: 20px 0;
  }
}
.hero-contact {
  background-color: #edeff1;
  padding: 0;
  .col-md-12 {
    padding: 20px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
      color: lighten($blk, 20%);
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 36px;
    }
    a {
      text-decoration: none;
      color: lighten($blk, 20%);
      font-weight: 100;
    }

    p {
      font-size:1.2em;
      line-height:1.5em;
      color: lighten($blk, 20%);
    }
    .footer-banner {
      margin-top: 7%;
      ul {
        padding-left: 0;
      }
    }
  }
  @media(max-width: 767px){
    img {max-width: 250px}
  }
}
@media(max-width:991px){
  img {
    max-width: 250px;
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background: $gray;
    color: lighten($wht, 20%);


}
